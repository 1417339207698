@import '../../resources/styles/colors';

#main {

  color: $white_text_primary;

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 200;
  }

  #icon {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: -50px;
  }

  svg {
    color: rgba(255, 255, 255, .7);
  }

  #store { margin-top: -30px; }

  #store-info {
    margin: 120px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    background-color: #323232;
    padding: 0 24px 32px;

    > * {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      min-width: 200px;
      max-width: 300px;
      margin: 32px 0 0;

      p {
        margin: 16px 0 0;
        font-size: 1.3rem;
        font-weight: 300;
        text-align: center;
      }
      .highlight {
        display: block;
        width: fit-content;
        margin: 8px auto;
        background-color: white;
        color: #323232;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.03rem;
        padding: 4px 10px;
        border-radius: 30px;
        text-transform: uppercase;
      }

      > svg {
        width: 1.8rem;
        height: 1.8rem;
        color: white;
        user-select: none;
      }

      .stars {
        position: absolute;
        top: 6px;

        > svg {
          display: inline-block;
          width: 1.1rem;
          height: 1.1rem;
          color: rgba(255, 193, 7, 1);
          user-select: none;
        }
      }
      .star-placeholder {
        opacity: 0;
      }
    }
  }

  #app-info {
    background-color: #424242;
    margin: 0 0 25px 0;
    padding: 24px 0 0;
    text-align: center;

    > p:first-of-type {
      font-size: 1.2rem;
      margin: 16px;
    }

    > #app-description {
      max-width: 632px;
      padding: 0 16px;
      text-align: justify;
      margin: 24px auto 48px;
    }

    > #app-features {
      background-color: #323232;
      padding: 48px 0;

      > h3 {
        margin: 0;
        text-align: center;
      }

      .app-features-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1050px;
        margin: 24px auto 0;

        > * {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          min-width: 250px;
          max-width: 350px;
          padding: 16px;

          svg {
            margin: 24px 0 0;
          }
          h4 {
            margin: 12px 0 0;
          }
          p {
            margin: 16px 0 0;
            text-align: justify;
          }
        }
      }
    }
  }

  .policies {
    display: flex;
    flex-direction: row;
    margin: 120px auto 0;
    max-width: 600px;
    flex-wrap: wrap;
    justify-content: center;

    > * {
      flex: 1;
      min-width: 300px;
      max-width: 300px;
      text-align: center;
    }
  }

  a.imaged {
    display: inline-block;
    margin: 10px 8px;
  }
  img.social-icon {
    vertical-align: middle;
    opacity: 0;
  }

  a#facebook {
    background: transparent url('../../resources/img/social_fb.png');
  }
  a#facebook:hover {
    background: transparent url('../../resources/img/social_fb_hover.png');
  }

  a#instagram {
    background: transparent url('../../resources/img/social_ig.png');
  }
  a#instagram:hover {
    background: transparent url('../../resources/img/social_ig_hover.png');
  }

  #social a {
    background-repeat: no-repeat;
    transition: background 0.15s;
  }

  .flag-icon {
    border-radius: 10px;
  }

  .flag-icon img {
    width: 50px;
    display: block;
    border-radius: 10px;
    opacity: 0.6;
    filter:         grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
    transition: all 0.15s;
  }

  .flag-icon:hover img {
    opacity: 1;
    filter:         grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter:    grayscale(0);
    -ms-filter:     grayscale(0);
    -o-filter:      grayscale(0);
  }

  #header       { margin: -140px 0 90px 0; }
  #social-title { margin:  120px 0 25px 0; }

  @media (max-width: 900px) {
    h1 { font-size: 4rem; }
  }

  @media (max-width:600px) {
    h1 { font-size: 3rem; }
  }

  @media (max-width:500px) {
    #icon { margin-top: -10%; }
    #header       { margin: -120px 0 80px 0; }
    #social-title { margin:   70px 0 15px 0; }
    .policies     { margin:   70px auto   0; }
  }

  @media (max-width:380px) {
    h1 { font-size: 2rem; }
    #header       { margin: -80px 0 60px 0; }
    #social-title { margin:  40px 0 10px 0; }
    .policies     { margin:  40px auto   0; }
    .store-badge, .social-icon {
      height: 100%;
      width: 100%;
    }
    #store a, #store a:hover {
      background-size: 100% 100%;
      margin: 10px 24px;
    }
  }

}
