
@import './colors.scss';
@import './fonts.scss';
@import './policy.scss';

html {
  background-color: $primary_dark;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; //'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: $text_primary;
  letter-spacing: 0.03125rem;
  font-weight: 400;
  line-height: 1.4;
}

#root {
  > .container, > .MuiContainer-root {
    padding-bottom: 100px;
  }
}

.secondary {
  color: $text_secondary;
}

.cinemaniac-font {
  font-family: 'Bernadette', 'Source Sans Pro', sans-serif;
  letter-spacing: 0;
}

.h1, .h2, .h3, h1, h2, h3 {
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 {
  font-size: 2.8rem;
  letter-spacing: -0.05rem;
  font-weight: 300;
}
h2 {
  font-size: 2.2rem;
  letter-spacing: -0.02rem;
  font-weight: 400;
}
h3 {
  font-size: 1.4rem;
  letter-spacing: 0;
  font-weight: 400;
}
h4 {
  font-size: 1.1rem;
  letter-spacing: 0;
  font-weight: 500;
  text-transform: uppercase;
}

p {
  letter-spacing: -0.002rem;
}

a {
  color: $accent;
  text-decoration: none;
}
a:hover {
  color: $accent_lighter;
  text-decoration: underline;
}

small {
  font-size: 0.75rem;
  letter-spacing: 0.025rem;
}

.center {
  display: block;
  text-align: center;
  margin: 0 auto;
}

hr:not(.MuiDivider-root) {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  width: 80%;
  margin: 4rem auto;
}

.stores-bg {
  margin-top: 48px;
  padding: 0 24px 128px 24px;
  text-align: center;
  color: $white_text_primary;

  > h2 {
    margin-bottom: 24px;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.8rem;
    letter-spacing: -0.05rem;
    font-weight: 300;
  }
  h2 {
    font-size: 2.2rem;
    letter-spacing: -0.02rem;
    font-weight: 400;
  }
  h3 {
    font-size: 1.4rem;
    letter-spacing: 0;
    font-weight: 500;
  }
  p {
    letter-spacing: -0.002rem;
  }
}

@media (max-width:600px) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}
