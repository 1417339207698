@import '../../resources/styles/colors';

.c-faq {
  background-color: $light_page;
  padding: 24px 0 48px 0;

  h2 {
    margin-top: 36px;
  }

  #summary {
    margin-block-end: -24px;
    padding-inline-start: 20px;
    line-height: 2;
  }

  h3 {
    margin-top: 24px;
    padding-top: 48px;
  }

  p {
    text-align: justify;
  }
}