
$primary: #009688;
$primary_dark: #00695C;
$accent: #FF6E40;
$accent_lighter: #FF9E80;
$light_page: #E0F2F1;

$text_primary: rgba(0, 0, 0, .87);
$text_secondary: rgba(0, 0, 0, .54);
$text_disabled: rgba(0, 0, 0, .38);

$white_text_primary: rgba(255, 255, 255, 1);
$white_text_secondary: rgba(255, 255, 255, .7);
$white_text_disabled: rgba(255, 255, 255, .5);
