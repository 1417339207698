@import '../../resources/styles/colors';

.policy {

  background-color: $light_page;
  padding: 24px 0;

  header {
    margin: 0 0 2rem 0;

    h1 {
      margin-bottom: 0.5rem;
    }
  }

  header .subtitle {
    font-size: 1.2em;
    margin-top: 0.5rem;
    color: rgba(0,0,0,0.6);
  }

  h2 {
    margin-top: 60px;
  }

  h3 {
    margin-top: 40px;
  }

  li {
    margin-bottom: 0.6rem;
  }

  .summary li {
    margin-bottom: 1.5rem;
  }

  li h4 {
    margin: 2rem 0 0 0;
  }

  li h5 {
    margin: 1rem 0 0 0;
  }

  p.secondary {
    font-size: 0.875rem;
    letter-spacing: 0.015625rem;
    line-height: 1.3;
    margin: 0.5rem 0;
  }

  .definitions {
    font-size: 0.7rem;
    line-height: 1.4;
    color: rgba(0,0,0,0.8);
  }

  footer {
    margin: 3em 0;
  }
}