@import '../../resources/styles/colors';

.c-content {

  .backdrop-wrapper {
    position: relative;
    width: 100%;
    background-color: #000000;
    overflow: hidden;

    img.blurred-bd {
      position: absolute;
      top: 50%;
      right: -20px;
      left: -20px;
      filter: blur(10px);
      width: calc(100% + 40px);
      z-index: 10;
      transform: translateY(-50%);
      user-select: none;
    }

    .backdrop-container {
      position: relative;
      width: 100%;

      img {
        display: block;
        width: 100%;
        user-select: none;

        &.backdrop {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 15;
        }

      }
    }
  }

  .content-info {
    background-color: $light_page;
    padding: 24px 0 36px 0;

    h2 {
      margin: 0;
    }

    .subtitle {
      margin-top: 0;
      margin-bottom: 24px;
      color: $text_secondary;
    }

    .overview {
      margin: 0;
      text-align: justify;
    }
  }

}