@import '../../resources/styles/colors';

.c-topbar {

  .MuiToolbar-root {
    background-color: $primary_dark;
    padding-left: 0;
    padding-right: 0;
  }

  .MuiContainer-root {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #topbar-icon {
    width: 64px;
    height: 64px;
    margin-right: 16px;
    margin-left: -5px;
    background: url("../../resources/img/logo.png") no-repeat 8px;
    background-size: 48px;
  }

  .topbar-logo {
    color: $white_text_primary;
    font-family: 'Bernadette', 'Source Sans Pro', sans-serif;
    font-size: 1.8rem;
    line-height: 1;
    text-decoration: none;
    margin-right: 32px;
  }

  .MuiButton-root:hover {
    background-color: #FF6E40;
  }
}

@media (max-width:400px) {
  .c-topbar {
    #topbar-icon {
      margin-right: 0;
      background-size: 36px;
    }
    .topbar-logo {
      font-size: 1.6rem;
      margin-top: -4px;
    }
  }
}

@media (max-width:300px) {
  .c-topbar {
    #topbar-icon {
      display: none;
    }
  }
}