#store {
  .store-badge {
    max-height: 68px;
    width: 100%;
    height: 100%;
  }

  a {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    transition: background 0.15s;
    display: inline-block;
    margin: 10px 8px;
    max-width: 100%;

    &#google {
      background-image: url('../../resources/img/google.png');

      &:hover {
        background-image: url('../../resources/img/google_hover.png');
      }
    }

    &#apple {
      background-image: url('../../resources/img/apple.png');

      &:hover {
        background-image: url('../../resources/img/apple_hover.png');
      }
    }
  }

  img.store-badge {
    vertical-align: middle;
    opacity: 0;
  }

}